<template>
  <div>
    <div v-show="showInformations" class="wrapper">
      <div class="wrapper__card">
        <div class="wrapper__card__header">
          <div class="left">
            <span>Documentação API - teste</span>
          </div>
          <div class="right">
            <z-btn text="ver log" color="primary" @click="goToPage('logsIndexPage')" />
            <!-- <z-btn text="ver log" primary /> -->
          </div>
        </div>

        <z-tabs :tabs="tabsScreen" android>
          <template #createDatas>
            <div class="wrapper__card__body">
              <div class="wrapper__card__body__left">
                <div class="card-key">
                  <strong>Sua chave de acesso:</strong>

                  <div class="card-key__line mt-2">
                    <span> {{ token }} </span>
                    <img src="/icons/copy-icon.svg" />
                  </div>
                </div>

                <div class="card-resource">
                  <span class="title-item"> Resource URL </span>
                  <span class="link-resource">
                    https://datatoact-back.eye.zooxsmart.com/api/dataInsertion/insert
                  </span>
                </div>

                <div class="card-parameters">
                  <span class="title-item">Parâmetros</span>
                  <div class="card-parameters__table">
                    <z-table class="table" :headers="headers" :items="tableItems">
                    </z-table>
                  </div>
                </div>
              </div>

              <div class="wrapper__card__body__right">
                <div class="how-test">
                  <span class="title-item">Como testar</span>
                  <p>
                    Para testar a API é necessário utilizar algum cliente HTTP, seja
                    gráfico (Postman, Insomnia...) ou programaticamente usando tecnoligias
                    como CURL, Node.js e Python.
                  </p>
                </div>

                <div class="tabs">
                  <z-tabs ref="tabs" android :tabs="tabs">
                    <!--   <template #primeira>
                 <span>conteudo da primeira</span>
            <CodeHighlight language="javascript">
                  const j = 1 import data
                  print(response)
                </CodeHighlight>

                <pre><code class="language-css">p { color: red }</code></pre>

                <pre>
                    <code class="language-javascript">
                        const p = 1
                        const x = 'some thing here'
                        </code>
                    </pre>
              </template> -->
                    <!-- <template #segunda>
                <span>Conteúdo da segunda</span>
              </template> -->

                    <template #terceira>
                      <CodeHighlight v-if="objectMounted != null" language="javascript">
                        <pre>
<code>
curl --location 'https://datatoact-back.eye.zooxsmart.com/api/dataInsertion/insert' \
--header 'Content-Type: application/json' \
--header 'token: {{ token }}' \
--data-raw '{
    "databaseId": "{{ connectionId }}",
    "configurationType": "{{connectionFromPage}}",
    "payload": {{objectMounted}}
}'
</code>
                    </pre>
                      </CodeHighlight>
                    </template>
                  </z-tabs>
                </div>
              </div>
            </div>
          </template>

          <template #addTags>
            <div class="wrapper__card__body">
              <div class="wrapper__card__body__left">
                <div class="card-key">
                  <strong>Sua chave de acesso:</strong>

                  <div class="card-key__line mt-2">
                    <span> {{ token }} </span>
                    <img src="/icons/copy-icon.svg" />
                  </div>
                </div>

                <div class="card-resource">
                  <span class="title-item"> Resource URL </span>
                  <span class="link-resource">
                    https://datatoact-back.eye.zooxsmart.com/api/dataInsertion/insert-tag
                  </span>
                </div>

                <div class="card-parameters">
                  <span class="title-item">Parâmetros</span>
                  <div class="card-parameters__table">
                    <z-table class="table" :headers="headers" :items="tableTagItems">
                    </z-table>
                  </div>
                </div>
              </div>

              <div class="wrapper__card__body__right">
                <div class="how-test">
                  <span class="title-item">Como testar</span>
                  <p>
                    Para testar a API é necessário utilizar algum cliente HTTP, seja
                    gráfico (Postman, Insomnia...) ou programaticamente usando tecnoligias
                    como CURL, Node.js e Python.
                  </p>
                </div>

                <div class="tabs">
                  <z-tabs ref="tabs" android :tabs="tabs">
                    <template #terceira>
                      <CodeHighlight v-if="objectMounted != null" language="javascript">
                        <pre>
<code>
curl --location 'https://datatoact-back.eye.zooxsmart.com/api/dataInsertion/insert-tag' \
--header 'Content-Type: application/json' \
--header 'token: {{ token }}' \
--data-raw '{
    "databaseId": "{{ connectionId }}",
    "userId": "26",
    "tag": "COMPROU ONTEM"
}'
</code>
                    </pre>
                      </CodeHighlight>
                    </template>
                  </z-tabs>
                </div>
              </div>
            </div>
          </template>
        </z-tabs>
      </div>
    </div>
    <div v-show="!showInformations" class="wrapper no-data">
      <div class="wrapper__card">
        <z-loading v-if="!errorOnFetchData" />
        <div v-else>
          <span>Não foi possivel buscar as informações solicitadas</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CodeHighlight from "vue-code-highlight/src/CodeHighlight.vue";
import "vue-code-highlight/themes/duotone-sea.css";
// import "vue-code-highlight/themes/window.css";

import moment from "moment";
import ApiClient from "@/services/ApiClient";
export default {
  components: {
    CodeHighlight,
  },
  data() {
    return {
      tabsScreen: [
        { id: "createDatas", text: "Inserir dados" },
        { id: "addTags", text: "Inserir tags" },
      ],
      errorOnFetchData: false,
      connectionId: "",
      connectionFromPage: "",
      objectMounted: null,
      showInformations: false,
      mock: {
        schema: "public",
        id: "8d79710e-334e-4485-81f1-b17d708ab736",
      },
      token: "",
      tableResponseMock: {
        id: "7d3b545a-a863-4346-b104-0ea46e3c6429",
        lastUpdate: "2023-03-16T16:09:55.295Z",
        nextUpdate: "2023-03-15T19:28:11.032Z",
        status: "error",
        company_id: "6e01104c-9957-4c20-9aeb-8edc4b2ddf9a",
        group_id: null,
        name: "test_att_api",
        dbIntegrationId: "1e8acad8-f2f6-4f37-b7ba-c5a25c1d8a45",
        updated: "2023-03-15T19:28:11.032Z",
        created: "2023-03-15T19:28:11.032Z",
        cron: "api",
        table: "AdditionalDataConfig",
        fields:
          '{"id":["id"],"name":["favorite"],"phone":["nextUpdate"],"sdkId":["apiEnabled"],"email":["apiEnabled"]}',
        primaryColumn: null,
        controlColumn: "id",
        favorite: null,
        typeConfig: "postgres",
        csvUrl: null,
        apiEnabled: false,
      },
      tableResponse: null,
      http: new ApiClient(),
      headers: [
        { text: "NOME DA VARIÁVEL", value: "name", sortable: true },
        { text: "TIPO", value: "type", sortable: true },
      ],
      items: [
        {
          id: 0,
          name: "Age",
          type: "Numeric",
        },
        {
          id: 1,
          name: "Gender",
          type: "Enum",
        },
        {
          id: 2,
          name: "Tenure",
          type: "Numeric",
        },
        {
          id: 3,
          name: "Balance",
          type: "Numeric",
        },
        {
          id: 4,
          name: "Geography",
          type: "Enum",
        },
        {
          id: 5,
          name: "HasCard",
          type: "Boolean",
        },
        {
          id: 6,
          name: "CreditStore",
          type: "Numeric",
        },
        {
          id: 7,
          name: "CreditStore",
          type: "Numeric",
        },
        {
          id: 8,
          name: "CreditStore",
          type: "Numeric",
        },
        {
          id: 9,
          name: "CreditStore",
          type: "Numeric",
        },
        {
          id: 10,
          name: "CreditStore",
          type: "Numeric",
        },
      ],
      dataTypes: {
        integer: "int",
        string: "texto",
        datetime: "Data Hora",
        date: "Data",
        decimal: "Decimal",
        bool: "Booleano",
      },
        dataExamples: {
          integer: "12345",
          string: "Text Field",
          datetime: "2023-02-01 20:26:04.000",
          date: "2023-02-01",
          decimal: "4,5",
          bool: "true",
        },
      tabs: [
        // { id: "primeira", text: "PYTHON" },
        // { id: "segunda", text: "NODE.JS" },
        { id: "terceira", text: "CURL" },
      ],
    };
  },
  created() {
    this.connectionFromPage = this.$route.params.page;
    this.connectionId = this.$route.params.id;

    if (this.connectionFromPage.toLowerCase() == "primarydata")
      this.getPrimaryDataInformation();
    if (this.connectionFromPage.toLowerCase() == "additionaldata")
      this.getAdditionalDataInformation();
    if (this.connectionFromPage.toLocaleLowerCase() == "conversiondata")
      this.getConversionDataInformation();
  },
  computed: {
    tableItems() {
      const FINAL_ARR = [];
      if (this.tableResponse != null || this.tableResponse != undefined) {
        //   const LIST = JSON.parse(this.tableResponseMock.fields);
        const LIST = JSON.parse(this.tableResponse.fields);
        const ARR = Object.keys(LIST);

        ARR.forEach((item, index) => {
          FINAL_ARR.push({
            id: index,
            name: item,
            type: this.dataTypes[LIST[item].type],
          });
        });
      }
      return FINAL_ARR;
    },
    tableTagItems() {
      const arr = [
        {
          name: "databaseId",
          type: "texto",
          id: 0,
        },
        {
          name: "userId",
          type: "texto",
          id: 1,
        },
        {
          name: "tag",
          type: "texto",
          id: 2,
        },
      ];

      return arr;
    },
  },
  methods: {
    goToPage(name) {
      this.$router.push({ name });
    },
    formateDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    mountObject() {
      this.objectMounted = null;
      if (this.tableResponse != null || this.tableResponse != undefined) {
        this.objectMounted = {};
        const list = JSON.parse(this.tableResponse.fields);
        const ARR = Object.keys(list);
        for (const item of ARR) {
          switch (item.toLocaleLowerCase()) {
            case "id":
              this.objectMounted[item] = "123456";
              break;
            case "name":
              this.objectMounted[item] = "Name";
              break;
            case "phone":
              this.objectMounted[item] = "21999999999";
              break;
            case "sdkid":
              this.objectMounted[item] = "123456";
              break;
            case "email":
              this.objectMounted[item] = "test@zooxsmart.com.br";
              break;
            default:
              this.objectMounted[item] = this.getDataExample(item, list);
          }
        }
      }
    },
    getDataExample(item, list) {
      if (list[item].type === "date" || list[item].type === "datetime") {
        console.log(list[item].format);
        return list[item].format;
      } else return this.dataExamples[list[item].type];
    },
    async getPrimaryDataInformation() {
      try {
        const req = await this.http.get(`dbConfiguration/${this.connectionId}`);
        this.tableResponse = req;
        this.token = this.tableResponse.apiToken;
        // console.log(req);
        this.mountObject();
        setTimeout(() => {
          this.showInformations = true;
        }, 1500);
        this.errorOnFetchData = false;
      } catch (error) {
        this.$toast.error("Erro ao buscar dados!");
        this.errorOnFetchData = true;
      }
    },
    async getAdditionalDataInformation() {
      try {
        const req = await this.http.get(
          `additionalDataConfiguration/${this.connectionId}`
        );
        this.tableResponse = req;
        this.token = this.tableResponse.apiToken;
        // console.log(req);
        this.mountObject();
        setTimeout(() => {
          this.showInformations = true;
        }, 1500);
        this.errorOnFetchData = false;
      } catch (error) {
        this.$toast.error("Erro ao buscar dados!");
        this.errorOnFetchData = true;
      }
    },
    async getConversionDataInformation() {
      try {
        const req = await this.http.get(
          `conversionDataConfiguration/${this.connectionId}`
        );
        this.tableResponse = req;
        this.token = this.tableResponse.apiToken;
        // console.log(req);
        this.mountObject();
        setTimeout(() => {
          this.showInformations = true;
        }, 1500);
        this.errorOnFetchData = false;
      } catch (error) {
        this.$toast.error("Erro ao buscar dados!");
        this.errorOnFetchData = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.wrapper {
  color: $z-black-1;
  padding: $z-s-1;
  height: calc(100vh - 48px);

  &__card {
    padding: $z-s-1;
    background: #ffffff;
    border-radius: 15px;
    height: 100%;
    overflow-y: auto;
    @include trackScrollBar;

    &__header {
      display: flex;
      align-items: center;
      gap: $z-s-1;
      margin-bottom: $z-s-2;
      flex-wrap: wrap;
      .left {
        flex: 1 1 auto;
        span {
          font-weight: 700;
          font-size: 1.1em;
          line-height: 15px;
        }
      }
      .right {
        flex: 2 1 600px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        gap: $z-s-1 0.5rem;
        & > div {
          &:first-child {
            width: 40%;
          }
        }

        @media (max-width: 808px) {
          justify-content: flex-start;
          & > div {
            flex: 1 1 350px;
          }
        }
      }
    }

    &__body {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: $z-s-1;

      & > div {
        flex: 1 1 400px;
        // border: 2px solid red;
        padding: 0.5rem;
        // width: 100%;
        // border: 1px solid red;
      }

      &__left {
        .card-key {
          background: #ececec;
          border-radius: 15px;
          padding: $z-s-1 1.5rem;
          &__line {
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
              cursor: pointer;
            }
          }
        }
        .card-resource {
          .link-resource {
            font-size: 0.95rem;

            @media (max-width: 750px) {
              font-size: 0.75rem;
            }
          }
        }

        .card-parameters {
          &__table {
            height: 35vh;
            overflow-y: auto;
            @include trackScrollBar;
          }
        }
        & > div {
          &:not(:last-child) {
            margin-bottom: 1.5rem;
          }
        }
      }
      &__right {
        .tabs {
          .curl {
            margin-top: 0.5rem;
            background: #272822;
            padding: 0.8rem 0.5rem;
            color: white;
            font-size: 0.7rem;
            border-radius: 10px;
          }
        }
      }
    }

    .title-item {
      font-size: 1.25rem;
      font-weight: 600;
      display: block;
      margin-bottom: 0.5rem;
    }

    &__create-info {
      max-width: 50%;
      padding: 1rem 0;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .form {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        .inpt {
          flex: 1 1 500px;
        }
      }
    }
  }
}
.no-data {
  .wrapper__card {
    height: 50vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    .loader {
      border: 16px solid #f3f3f3;
      border-radius: 50%;
      border-top: 16px solid #3498db;
      width: 120px;
      height: 120px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
    }
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    span {
      font-weight: 700;
      font-size: 1.1em;
      line-height: 15px;
    }
  }
}
</style>
